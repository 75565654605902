<template>
  <div :id="id" :class="className"></div>
</template>

<script>
import * as echarts from "echarts";
import { watch } from "fs";
export default {
  props: ["options", "id", "className"],
  watch: {
    options(val) {
      this.drawEcharts()
    },
  },
  data() {
    return {};
  },
  methods: {
    drawEcharts() {
      var myChart = echarts.init(document.getElementById(this.id));
      myChart.setOption(this.options);
    }
  },
  mounted: function() {
    this.drawEcharts();
  },
};
</script>

<style lang="less" scoped></style>
